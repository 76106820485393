import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from "@mui/material";
import { React, useEffect, useState } from 'react';
import useCurrency from "../../hooks/useCurrency";
import ToolCard from "../utils/ToolCard";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.grey[200],
    }
}))

const CurrencyEditor = () => {
    const currencyContext = useCurrency();
    const [localList, setLocalList] = useState([]);
    const [inputState, setInputState] = useState("");

    useEffect(() => {
        console.log("CurrencyEditor useEffect");
        if (currencyContext.currencyList === undefined) {
            return;
        }
        setLocalList(currencyContext.currencyList);
    }, [currencyContext.currencyList]);

    const removeCurrency = (x) => {
        setLocalList(oldVal => oldVal.filter(y => y !== x));
    }

    const addCurrency = (x) => {
        if (!isValidCurrencyToAdd(x)) {
            return;
        }
        setLocalList(oldVal => [...oldVal, x]);
    }

    const isValidCurrencyToAdd = (x) => {
        return x !== "" && !localList.includes(x) && !x.includes(" ");
    }

    const saveCurrencyList = async () => {
        await currencyContext.updateCurrencyList(localList);
    }

    const discardChanges = () => {
        setLocalList(currencyContext.currencyList);
    }

    const addedCurrencies = localList.filter(x => !currencyContext.currencyList.includes(x));
    const removedCurrencies = currencyContext.currencyList.filter(x => !localList.includes(x));

    return (
        <ToolCard 
            title="Edit Currencies" 
            description="Edit the list of currencies that are available for use in the credit tool." 
            isLoading={currencyContext.loading}>

                <TableContainer component={Paper} sx={{ maxWidth: 600, mx: 'auto', mb: 4, maxHeight: 600, overflowY: 'scroll' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Currency ID</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { localList.length === 0 && <TableRow><TableCell colSpan={2} align="center">No currencies found.</TableCell></TableRow> }
                            { localList.map((x,i) => (
                                <StyledTableRow>
                                    <TableCell>{x}</TableCell>
                                    <TableCell align="right">
                                        <IconButton variant="contained" size="small" color="error" onClick={() => removeCurrency(x)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box display={"flex"} alignContent={"center"} justifyContent={"center"}>
                    <TextField id="currency-input" label="Currency Name" variant="outlined" size="small" sx={{ mb: 2 }} value={inputState} onChange={(e) => setInputState(e.target.value)} />
                    <Button variant="contained" color="primary" sx={{ mb: 2, ml: 2 }} onClick={() => addCurrency(inputState)} disabled={!isValidCurrencyToAdd(inputState)}>ADD CURRENCY</Button>
                    <Button variant="contained" color="success" sx={{ mb: 2, ml: 'auto' }} onClick={saveCurrencyList}>SAVE CHANGES</Button>
                    <Button variant="contained" color="error" sx={{ mb: 2, ml: 2 }} onClick={discardChanges}>DISCARD CHANGES</Button>
                </Box>

                <Box px={4} py={2} sx={{mt: 4, backgroundColor: 'grey.100'}}>
                    <Typography mb={2} fontWeight={"bold"}>Changes:</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography>Added Currencies:</Typography>
                            <ul>
                                {addedCurrencies.map(x => (
                                    <li key={x}>{x}</li>
                                ))}
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>Removed Currencies:</Typography>
                            <ul>
                                {removedCurrencies.map(x => (
                                    <li key={x}>{x}</li>
                                ))}
                            </ul>
                        </Grid>
                    </Grid>
                </Box>

        </ToolCard>
    );
}

export default CurrencyEditor;